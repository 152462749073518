import React, { useEffect, useState } from 'react';
import useMultiCloudinaryUpload from './useMultiCloudinaryUpload';
import { ProvisionalContentMedium } from '../types';
import CustomCloseButton from './CustomCloseButton';
import Spinner from './Spinner';

interface PixabaySearchProps {
  onImageSelect: React.Dispatch<React.SetStateAction<boolean>>;
  hContentMedia: Record<number, ProvisionalContentMedium>;
  profile_picture: string;
  setHContentMedia: React.Dispatch<React.SetStateAction<Record<number, ProvisionalContentMedium>>>;
  afterAddFn: (contentMediaIds: number[]) => void;
}

interface PixabayImage {
  id: number;
  largeImageURL: string;
  tags: string;
}


const PixabaySearch: React.FC<PixabaySearchProps> = ({
  onImageSelect,
  hContentMedia,
  profile_picture,
  setHContentMedia,
  afterAddFn,
}) => {  
  const [query, setQuery] = useState('');  
  const [images, setImages] = useState<PixabayImage[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [insertLogo, setInsertLogo] = useState(false);
  const [page, setPage] = useState(1);
  const [showMenu, setShowMenu] = useState(false);
  const [isSaving, setIsSaving] = useState(false); 
  const [logoPosition, setLogoPosition] = useState<'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'center'>('top-left'); // default position
  const [logoShape, setLogoShape] = useState<'rounded-square' | 'circle'>('rounded-square'); // default form
  const { uploadFiles } = useMultiCloudinaryUpload({
    addMedia: (files) => {
      const lastCMId = Math.max(...Object.keys(hContentMedia).map(k => parseInt(k, 10)), 0) + 1;
      const newCMs = files.map((file, index) => {
        const newId = lastCMId + index;
        return [newId, { provisional_id: newId, serialized_file: file }];
      });      
      setHContentMedia(prev => ({ ...prev, ...Object.fromEntries(newCMs) }));
      afterAddFn(newCMs.map(c => c[0]) as number[]);
      onImageSelect(false);
    }
  });

  const urlToFile = async (url: string, filename: string, mimeType: string): Promise<File> => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: mimeType });
  };

  const getPublicIdFromUrl = (url: string) => {
    const regex = /\/upload\/(?:v\d+\/)?(.+)\.\w+$/;
    const match = url.match(regex);    
    return match ? match[1] : null;
  };

  const uploadAndGetPublicId = async (file: any) => {
    const timestamp = Date.now().toString();

    const signatureResponse = await fetch(`/api/sign_cloudinary_upload?timestamp=${timestamp}&return_delete_token=true`);
    const { signature } = await signatureResponse.json();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("api_key", "883898914556696");
    formData.append("timestamp", timestamp);
    formData.append("return_delete_token", "true");
    formData.append("signature", signature);

    const uploadResponse = await fetch(`https://api.cloudinary.com/v1_1/dcmekntwa/upload`, {
      method: "POST",
      body: formData,
    });
    const uploadResult = await uploadResponse.json();

    return uploadResult.public_id;
  };

  const insertLogoOnImage = async () => {
    if (!selectedImage || !profile_picture) return;
  
    const baseImagePublicId = await uploadAndGetPublicId(await urlToFile(selectedImage, 'pixabay_image.png', 'image/png'));
    const logoPublicId = getPublicIdFromUrl(profile_picture) || await uploadAndGetPublicId(await urlToFile(profile_picture, 'logo.png', 'image/png'));
  
    const gravityMap = {
      'top-left': 'north_west',
      'top-right': 'north_east',
      'bottom-left': 'south_west',
      'bottom-right': 'south_east',
      'center': 'center'
    };

    const radiusMap = {
      'rounded-square': 'r_20',
      'circle': 'r_max'
    };

    const gravity = gravityMap[logoPosition];
    const radius = radiusMap[logoShape];

    const transformedImageUrl = `https://res.cloudinary.com/dcmekntwa/image/upload/b_white,c_fill,r_20,fl_layer_apply,l_${logoPublicId},${radius},w_200,g_${gravity},x_10,y_10/${baseImagePublicId}`;
    const response = await fetch(transformedImageUrl);
    const blob = await response.blob();
    const finalFile = new File([blob], 'combined_image.png', { type: 'image/png' });
  
    uploadFiles([finalFile]);
  };

  const getPositionClassesForRedSquares = (position: string) => {
    switch(position) {
      case 'top-left':
        return 'top-0 left-0';
      case 'top-right':
        return 'top-0 right-0';
      case 'bottom-left':
        return 'bottom-0 left-0';
      case 'bottom-right':
        return 'bottom-0 right-0';
      case 'center':
        return 'top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
      default:
        return 'top-0 left-0';
    }
  };

  const uploadSelectedImage = async () => {
    setIsSaving(true); // Commence le chargement
    try {
      if (insertLogo) {
        await insertLogoOnImage();
      } else {
        if (!selectedImage) return;
        const file = await urlToFile(selectedImage, 'image.jpg', 'image/jpeg');
        uploadFiles([file]);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const searchImages = async () => {
    const apiKey = '44481271-e0dcc26e9803ee1deb1260df5';
    const url = `https://pixabay.com/api/?key=${apiKey}&q=${encodeURIComponent(query)}&image_type=photo&per_page=20&page=1`;
    try {
      setLoading(true);
      const response = await fetch(url);
      const data = await response.json();
      setImages(data.hits);
      setPage(1);
    } catch (error) {
      console.error("Error fetching Pixabay images", error);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreImages = async () => {
    const apiKey = '44481271-e0dcc26e9803ee1deb1260df5';
    const nextPage = page + 1;
    const url = `https://pixabay.com/api/?key=${apiKey}&q=${encodeURIComponent(query)}&image_type=photo&per_page=20&page=${nextPage}`;
    
    try {
      setLoading(true);
      const response = await fetch(url);
      const data = await response.json();
      setImages(prevImages => [...prevImages, ...data.hits]); 
      setPage(nextPage); 
    } catch (error) {
      console.error("Error fetching more Pixabay images", error);
    } finally {
      setLoading(false);
    }
  };

  const getPositionClasses = (position: string) => {
    switch(position) {
      case 'top-left':
        return 'top-2 left-2';
      case 'top-right':
        return 'top-2 right-2';
      case 'bottom-left':
        return 'bottom-2 left-2';
      case 'bottom-right':
        return 'bottom-2 right-2';
      case 'center':
        return 'top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
      default:
        return 'top-2 left-2';
    }
  };

  const getShapeClasses = (shape: string) => {
    switch(shape) {
      case 'rounded-square':
        return 'rounded-sm';
      case 'circle':
        return 'rounded-full';
    }
  };

  return (
    <div className="relative w-full max-w-[800px] h-full max-h-[600px] p-4 sm:p-8 md:p-12 border border-gray-300 rounded-lg bg-white shadow-lg flex flex-col">
      <CustomCloseButton onClickEvent={() => !isSaving && onImageSelect(false)} />
      <div className="relative w-full">
        <input
          type="text"
          name="search"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault(); 
              searchImages();     
            }
          }}
          placeholder="Rechercher..."
          className="peer form-input flex flex-1 w-full rounded-md shadow-sm pl-5 pr-3 py-1 border border-gray-300 rounded-full focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20"
        />
        <button
          onClick={searchImages}
          className="
            absolute h-full border border-gray-300 bottom-0 bg-gray-50 flex items-center
            rounded-r-md p-2 right-0 hover:bg-gray-100 text-gray-400 duration-100
            peer-focus:border-brand_focus
          "
        >
          <i className="fa fa-search duration-100"></i>
        </button>
      </div>
      {loading && <p className="text-center mb-4">Chargement...</p>}
      <div className="flex-1 overflow-y-auto mb-4">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mt-5">
          {images.map((image) => (
            <div
              key={image.id}
              onClick={() => setSelectedImage(image.largeImageURL)}
              className={`relative cursor-pointer rounded overflow-hidden border-2 ${
                selectedImage === image.largeImageURL ? 'border-blue-500' : 'border-transparent'
              }`}
            >
              <img
                src={image.largeImageURL}
                alt={image.tags}
                className="w-full h-32 object-cover"
              />
              {insertLogo && profile_picture && (
                <img
                  src={profile_picture}
                  alt="Logo"
                  className={`absolute w-5 h-fit object-contain ${getPositionClasses(logoPosition)} ${getShapeClasses(logoShape)}`}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      {images.length > 0 && (
        <>
<div className="my-2 flex items-center">
  <button
    className={`btn-brand-primary flex-grow py-2 rounded relative w-full sm:w-auto ${
      !profile_picture ? 'opacity-50 cursor-not-allowed' : ''
    }`}
    onClick={() => {
      setShowMenu(!showMenu);
      setInsertLogo(!insertLogo);
    }}
    disabled={!profile_picture || isSaving}
  >
    {!profile_picture
      ? "Votre entreprise n’a pas de logo"
      : insertLogo
      ? 'Enlever le logo'
      : 'Insérez votre logo'}
  </button>
</div>          {showMenu && (
            <div className='my-5 flex flex-col sm:flex-row justify-between items-center gap-4'>
              <div className='relative w-32 h-32 border border-gray-300 rounded-md'>
                <div className='w-full h-full'></div>
                {insertLogo && profile_picture && (
                  <img
                    src={profile_picture}
                    alt="Logo"
                    className={`absolute w-8 h-8 object-contain border border-brand_focus ${getPositionClassesForRedSquares(logoPosition)} ${getShapeClasses(logoShape)}`}
                  />
                )}
                <div className='absolute inset-0 rounded-md'>
                  <div
                    className='absolute top-0 left-0 w-8 h-8 cursor-pointer border border-brand_focus hover:border-brand_main'
                    onClick={() => setLogoPosition('top-left')}
                  />
                  <div
                    className='absolute top-0 right-0 w-8 h-8 cursor-pointer border border-brand_focus hover:border-brand_main'
                    onClick={() => setLogoPosition('top-right')}
                  />
                  <div
                    className='absolute bottom-0 left-0 w-8 h-8 cursor-pointer border border-brand_focus hover:border-brand_main'
                    onClick={() => setLogoPosition('bottom-left')}
                  />
                  <div
                    className='absolute bottom-0 right-0 w-8 h-8 cursor-pointer border border-brand_focus hover:border-brand_main'
                    onClick={() => setLogoPosition('bottom-right')}
                  />
                  <div
                    className='absolute top-1/2 left-1/2 w-8 h-8 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer border border-brand_focus hover:border-brand_main'
                    onClick={() => setLogoPosition('center')}
                  />
                </div>
              </div>
              <button
                className="bg-brand_main text-white py-2 px-4 rounded hover:bg-brand_focus w-full sm:w-auto"
                onClick={() => {
                  setLogoShape(prevShape => prevShape === 'circle' ? 'rounded-square' : 'circle');
                }}
              >
                Changer la forme
              </button>
            </div>
          )}
          <div className="flex flex-col-reverse sm:flex-row-reverse gap-4">
            <button
              onClick={uploadSelectedImage}
              className={`flex-grow py-2 rounded relative w-full sm:w-auto
                ${selectedImage ? 'btn-brand-primary' : 'bg-gray-200 text-black'} 
                ${isSaving ? 'cursor-not-allowed opacity-50' : ''}`}
              disabled={isSaving || !selectedImage}
            >
              {isSaving ? (
                <Spinner
                  className={`absolute inset-0 m-auto w-5 h-5 text-white animate-spin`}
                />
              ) : !selectedImage ? (
                "Veuillez sélectionner une image avant d'enregistrer"
              ) : (
                "Enregistrer"
              )}
            </button>
            <button
              onClick={loadMoreImages}
              className="btn-brand-primary flex-grow py-2 rounded w-full sm:w-auto"
              disabled={isSaving}
            >
              Charger plus d'images
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default PixabaySearch;